<template>
    <div class="project-detail">
        <van-row type="flex" justify="center" align="center" class="card">
            <van-col span="20">
                <van-field style="border-radius: 6px 0 0 0;" label="标题" :value="cardInfo.title"></van-field>
                <van-field label="计划出发时间" :value="cardInfo.startTime"></van-field>
                <van-field style="border-radius: 0 0 0 6px;" label="计划返回时间" :value="cardInfo.endTime"></van-field>
            </van-col>
            <van-col span="4">
                <van-row>
                    <van-icon name="envelop-o"/>
                </van-row>
                <van-row><span>提交oa</span></van-row>
            </van-col>
        </van-row>

        <div class="list" v-for="(item,index) in list" v-bind:key="index">
            <van-row type="flex" justify="start" align="center">
                <van-col span="20">
                    <van-row class="list-title">
                        {{item.title}}
                    </van-row>
                    <van-row>
                        <van-col class="list-info" span="4">{{item.user}}</van-col>
                        <van-col class="list-info" span="6">{{item.nature}}</van-col>
                        <van-col class="list-info" span="6">{{item.type}}</van-col>
                        <van-col class="list-info" span="8">{{item.project}}</van-col>
                    </van-row>
                    <van-row class="list-info">
                        {{item.address}}
                    </van-row>
                </van-col>
                <van-col span="4" @click="to(item.to)">
                    <van-icon name="browsing-history"/>
                </van-col>
            </van-row>
        </div>
        <crm-tab-bar/>
    </div>
</template>

<script>
    import CrmTabBar from "@/components/CrmTabBar";
    export default {
        name: "ProjectProgressDetail",
        components:{CrmTabBar},
        data() {
            return {
                cardInfo: {
                    startTime: '2020-01-01',
                    endTime: '2020-12-12',
                    title: '哈尔滨项目推进'
                },
                list: [
                    {
                        id: Math.random(),
                        title: '中国城通国际贸易有限公司',
                        user: '李阳',
                        nature: '贸易型',
                        type: '货方客户',
                        project: '钢铁东北项目组',
                        address: 'xx省xx市详细地址',
                        to: 'ProjectRecordDetail',
                    },
                    {
                        id: Math.random(),
                        title: '中国城通国际贸易有限公司',
                        user: '李阳',
                        nature: '贸易型',
                        type: '货方客户',
                        project: '钢铁东北项目组',
                        address: 'xx省xx市详细地址',
                        to: 'ProjectRecordDetail',
                    }
                ]
            }
        },
        methods: {
            to(to) {
                this.$router.push(to);
            }
        }
    }
</script>

<style scoped>
    .project-detail {
        background: #f3f3f3;
    }

    .card {
        margin: 10px 5px;
        background: #378FF2;
        border-radius: 6px;
        box-shadow: 6px 6px 5px #888888;
    }

    .list {
        background: #FFFFFF;
        padding: 5px 10px;
        margin-top: 5px;
    }

    .list-title {
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        font-style: normal;
        letter-spacing: 0;
        line-height: 24px;
        text-decoration: none;
    }

    .list-info {
        padding-top: 5px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: #827E7E;
        font-style: normal;
        letter-spacing: 0;
        line-height: 20px;
        text-decoration: none;
    }
</style>
